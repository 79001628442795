// new modal logic - used in CoR Diagram
const open = id => {
  if (!id) {
    return;
  }
  const modalOverlay = document.querySelector('#modal__overlay');
  const modal = document.getElementById(id);
  modalOverlay.classList.toggle('modal--closed');
  modal.classList.toggle('modal--closed');
};

const close = modal => {
  const modalOverlay = document.querySelector('#modal__overlay');
  modal.classList.toggle('modal--closed');
  modalOverlay.classList.toggle('modal--closed');
};

const modal = {
  open,
  close
};

// old modal logic
const modalLogic = function modalLogic(modal) {
  if (!modal) {
    return;
  }

  // const modal = document.querySelector('#modal');
  const modalOverlay = document.querySelector('#modal__overlay');
  const closeButton = document.querySelector('#button__close');
  const openButton = document.querySelector('#modal__button');

  closeButton.addEventListener('click', function() {
    modal.classList.toggle('modal--closed');
    modalOverlay.classList.toggle('modal--closed');
  });

  openButton.addEventListener('click', function() {
    modal.classList.toggle('modal--closed');
    modalOverlay.classList.toggle('modal--closed');
  });
};

export { modal, modalLogic };
