import axios from 'axios';
import dompurify from 'dompurify';

const searchResultsHTML = function searchResultsHTML(students) {
  return students
    .map(student => {
      return `
      <a href="/student/${student._id}" class="search__result">
        <strong>${student.lastName}, ${student.firstName}</strong>
      </a>
    `;
    })
    .join('');
};

const studentSearch = function studentSearch(search) {
  if (!search) {
    return;
  }

  const searchInput = search.querySelector('input[name="search"]');
  const searchResults = search.querySelector('.search__results');
  const school = searchInput.attributes['data-school'].value;

  searchInput.on('input', function() {
    // if there is no value, quit it!
    if (!this.value) {
      searchResults.style.display = 'none';
      return; // stop
    }

    // show the results!
    searchResults.style.display = 'block';

    axios
      .get(`/api/v1/search/students?school=${school}&q=${this.value}`)
      .then(res => {
        if (res.data.length) {
          // console.log('there is something show');
          searchResults.innerHTML = dompurify.sanitize(
            searchResultsHTML(res.data)
          );
          return;
        }
        // tell them nothing came back
        searchResults.innerHTML = dompurify.sanitize(
          `<div class="search__result">No results for ${
            this.value
          } found!</div>`
        );
      })
      .catch(err => {
        console.error(err);
      });
  });

  // handle keyboard inputs
  searchInput.on('keyup', e => {
    // if they aren't pressing up, down or enter, who cares
    if (![38, 40, 13].includes(e.keyCode)) {
      return;
    } //skip it
    const activeClass = 'search__result--active';
    const current = search.querySelector(`.${activeClass}`);
    const items = search.querySelectorAll('.search__result');
    let next;
    if (e.keyCode === 40 && current) {
      next = current.nextElementSibling || items[0];
    } else if (e.keyCode === 40) {
      next = items[0];
    } else if (e.keyCode === 38 && current) {
      next = current.previousElementSibling || items[items.length - 1];
    } else if (e.keyCode === 38) {
      next = items[items.length - 1];
    } else if (e.keyCode === 13 && current.href) {
      window.location = current.href;
      return;
    }
    if (current) {
      current.classList.remove(activeClass);
    }
    next.classList.add(activeClass);
  });
};

const userSearchResultsHTML = function userSearchResultsHTML(users) {
  return users
    .map(user => {
      const { lastName, firstName, role, accountStatus, _id } = user;
      console.log(user);

      const html = `
      <div class="search__result">
        ${lastName},  ${firstName} |
        ${role} | 
        ${user.school.name} |
        ${
          accountStatus
            ? 'active'
            : `<a href=/account/approve/${_id}> Approve </a>`
        }
      </div>
    `;

      return html;
    })
    .join('');
};

const userSearch = function userSearch(search) {
  if (!search) {
    return;
  }

  const searchInput = search.querySelector('input[name="search"]');
  const searchResults = search.querySelector('.search__results');
  // const school = searchInput.attributes['data-school'].value;

  searchInput.on('input', function() {
    // if there is no value, quit it!
    if (!this.value) {
      searchResults.style.display = 'none';
      return; // stop
    }

    // show the results!
    searchResults.style.display = 'block';

    axios
      .get(`/api/v1/search/users?&q=${this.value}`)
      .then(res => {
        if (res.data.length) {
          console.log('there is something show');
          searchResults.innerHTML = dompurify.sanitize(
            userSearchResultsHTML(res.data)
          );
          return;
        }
        // tell them nothing came back
        searchResults.innerHTML = dompurify.sanitize(
          `<div class="search__result">No results for ${
            this.value
          } found!</div>`
        );
      })
      .catch(err => {
        console.error(err);
      });
  });

  // handle keyboard inputs
  searchInput.on('keyup', e => {
    // if they aren't pressing up, down or enter, who cares
    if (![38, 40, 13].includes(e.keyCode)) {
      return;
    } //skip it
    const activeClass = 'search__result--active';
    const current = search.querySelector(`.${activeClass}`);
    const items = search.querySelectorAll('.search__result');
    let next;
    if (e.keyCode === 40 && current) {
      next = current.nextElementSibling || items[0];
    } else if (e.keyCode === 40) {
      next = items[0];
    } else if (e.keyCode === 38 && current) {
      next = current.previousElementSibling || items[items.length - 1];
    } else if (e.keyCode === 38) {
      next = items[items.length - 1];
    } else if (e.keyCode === 13 && current.href) {
      window.location = current.href;
      return;
    }
    if (current) {
      current.classList.remove(activeClass);
    }
    next.classList.add(activeClass);
  });
};

export { studentSearch, userSearch };
