const getSignedRequest = async function(file) {
  try {
    const response = await fetch(
      `/task/submit/image/sign-s3?file-name=${file.name}&file-type=${file.type}`
    );
    return response.json();
  } catch (error) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
};
const uploadFile = async function(file, signedRequest, url) {
  const options = {
    method: 'PUT',
    body: file
  };
  try {
    const response = await fetch(signedRequest, options);
  } catch (error) {
    const message = `${response.status}: ${response.statusText}`;
    alert('Server Error');
    console.error(message);
  }
  return url;
};
const handleImageUpload = async function(form) {
  if (!form) {
    return;
  }
  const preview = document.getElementById('image__preview');
  const button = document.getElementById('submit');
  const loader = document.getElementById('loader');
  // grab the files
  const { files } = document.getElementById('photo');
  // check to see if there are any files
  if (!files || !files.length) {
    return;
  }
  // toggle the spinner
  loader.classList.toggle('visually-hidden');
  // disable the button until upload is complete
  button.disabled = true;

  // loop through all the images
  for (var i = 0; i < files.length; i++) {
    console.log(files[i]);
    // check to make sure they are images
    if (!files[i].type) {
      continue;
    }
    const mimeType = files[i].type.split('/');
    if (mimeType[0] != 'image') {
      const message = `${files[i].name} is not an image`;
      alert(message);
      continue;
    }
    try {
      // get presigned url for each file
      const signedRequest = await getSignedRequest(files[i]);
      console.log(signedRequest);
      // upload the file
      const url = await uploadFile(
        files[i],
        signedRequest.signedRequest,
        signedRequest.url
      );
      console.log(url);
      // append the thumbnails to the preview
      const li = document.createElement('li');
      preview.appendChild(li);
      const img = document.createElement('img');
      img.src = url;
      img.classList.add('img--thumb');
      li.appendChild(img);
      // append the urls to the form field
      const imgInput = document.createElement('input');
      imgInput.hidden = true;
      imgInput.name = 'photo-url';
      imgInput.value = url;
      form.appendChild(imgInput);
      // console.log(form[0]);
    } catch (error) {
      // throw new Error(`${response.status}: ${response.statusText}`);
      throw new Error(error);
    }
  }
  // turn off the loader
  loader.classList.toggle('visually-hidden');
  // re-enable to button
  button.disabled = false;
  return;
};

export { handleImageUpload };
