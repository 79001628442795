const emCalc = function emCalc(c, v, con, vol) {
  //- c is PDF Entry line
  //- v is Total volume
  //- con is all the FEL concentration values
  //- vol is all and FEL volume values
  let felEquation = 0;
  for (let i = 0; i < con.length; i++) {
    const conValue = con[i].value;
    const volValue = vol[i].value;
    if (conValue.length == 0 || volValue.length == 0) {
      continue;
    } else {
      let c1 = conValue * 1;
      let v1 = volValue * 1;
      felEquation = felEquation + c1 * v1;
    }
  }
  if (felEquation == 0) {
    alert('no values');
    return;
  }
  if (c == null) {
    return felEquation / v;
  } else if (v == null) {
    return felEquation / c;
  }
};

const emCalcPrep = function emCalcPrep(num) {
  let n = num.toString();
  n++;
  const PDF = document.getElementsByName('emsol-pdf');
  const PDFVal = PDF[num].value;
  const v = document.getElementsByName('emsol-vol');
  const vVal = v[num].value;
  const con = document.getElementsByName('emsol-' + n + '-con');
  const vol = document.getElementsByName('emsol-' + n + '-vol');
  if (PDFVal.length == 0) {
    const calc = emCalc(null, v[0 + num].value, con, vol);
    if (calc) {
      PDF[num].value = calc;
    }
  } else if (vVal.length == 0) {
    const calc = emCalc(PDF[0 + num].value, null, con, vol);
    if (calc) {
      v[num].value = calc;
    }
  } else {
    const calc = emCalc(null, v[0 + num].value, con, vol);
    if (calc) {
      PDF[num].value = calc;
    }
  }
};

const emCalcReset = function emCalcReset(num) {
  // make sure the number is a string
  let n = num.toString();
  // increment
  n++;
  // reset the pdf line
  let PDF = document.getElementsByName('emsol-pdf');
  PDF[num].value = '';
  // reset the total volume
  let v = document.getElementsByName('emsol-vol');
  v[num].value = '';
  // reset all the fels, cons and vols
  let fel = document.getElementsByName('emsol-' + n + '-fel');
  let con = document.getElementsByName('emsol-' + n + '-con');
  let vol = document.getElementsByName('emsol-' + n + '-vol');
  for (let i = 0; i < con.length; i++) {
    fel[i].value = '';
    con[i].value = '';
    vol[i].value = '';
  }
};

export { emCalc, emCalcPrep, emCalcReset };
