import axios from 'axios';

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

// get export
const getPDF = async userID => {
  const pdf = await axios
    .get(`/api/v1/reports/${userID}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    })
    .catch(error => {
      document.getElementById('downloadStatusDesc').textContent = error.message;
      throw new Error(error);
    });
  return pdf;
};

const savePDF = async userID => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const statusDiv = document.getElementById('downloadStatus');
  const saveButton = document.getElementById('savePDFButton');
  const downloadStatusDesc = document.getElementById('downloadStatusDesc');
  downloadStatus.classList.remove('visually-hidden');

  downloadStatusDesc.textContent =
    'Gathering Data...this may take awhile depending on your internet connection';
  saveButton.disabled = true;
  saveButton.classList.toggle('visually-hidden');

  await getPDF(userID) // API call
    .then(response => {
      downloadStatusDesc.textContent = 'Creating PDF.';
      // name pdf
      const pdfName = 'MorTraqr_export';

      // create UI elements
      const link = document.createElement('a');
      link.download = `${pdfName}.pdf`;
      link.text = 'download';

      // create a blob
      const blob = new Blob([response.data], { type: 'application/pdf' });
      // downloading blobs doesn't work in iOS
      // https://stackoverflow.com/questions/53666113/file-not-downloading-with-blob-object-in-iphone-chrome-browser

      // get os type
      var os = getMobileOperatingSystem();

      if (window.navigator.msSaveOrOpenBlob) {
        //IE 11+
        window.navigator.msSaveOrOpenBlob(blob, `${pdfName}.pdf`);
      } else if (userAgent.match('FxiOS')) {
        //FF iOS
        downloadStatusDesc.text = `Error:Cannot display on FF iOS`;
        return;
      } else if (userAgent.match('CriOS')) {
        //Chrome iOS
        var reader = new FileReader();
        reader.onloadend = function() {
          window.open(reader.result);
        };
        reader.readAsDataURL(blob);
      } else if (os == 'iOS') {
        var url = window.URL.createObjectURL(blob);
        link.href = url;
      } else {
        link.href = window.URL.createObjectURL(blob);
        statusDiv.appendChild(link);
        // link.click();
      }

      downloadStatusDesc.textContent = 'Created!';
    })
    .catch(error => {
      saveButton.classList.toggle('visually-hidden');
      saveButton.disabled = false;
      downloadStatusDesc.textContent = error.message;
      console.error(error);
    });
};

export { getPDF, savePDF };
