const sorter = function sorter(sort) {
  if (!sort) {
    return;
  }
  const filter = document.getElementById('sort-filter');

  filter.addEventListener('click', function() {
    const sortDir = this.classList[0];
    toggleSort(sortDir);
  });

  sort.addEventListener('change', function() {
    const sortDir = document.getElementById('sort-filter').classList[0];
    sortElements(this.value, sortDir);
  });
};

const toggleSort = function toggleSort(dir) {
  const filter = document.getElementById('sort-filter');
  // const sortDir = filter.classList[0];
  const sort = document.getElementById('sort');
  if (dir === 'asc') {
    sortElements(sort.value, 'desc');
    filter.classList.remove('asc');
    filter.classList.add('desc');
    // filter.classList;
  } else if (dir === 'desc') {
    sortElements(sort.value, 'asc');
    filter.classList.remove('desc');
    filter.classList.add('asc');
  }
  // debugger;
};

const sortElements = function sortElements(option, dir = 'asc') {
  let i, switching, item, shouldSwitch;
  // console.log(option);
  const list = document.getElementById('list');
  // debugger;
  switching = true;
  /* Make a loop that will continue until
  no switching has been done: */
  while (switching) {
    // Start by saying: no switching is done:
    switching = false;
    item = list.getElementsByTagName('ul');
    // Loop through all list items:
    for (i = 0; i < item.length - 1; i++) {
      // Start by saying there should be no switching:
      shouldSwitch = false;
      /* Check if the next item should switch place with the current item,
      based on the sorting direction (asc or desc): */
      if (dir === 'desc') {
        if (item[i].dataset[option] > item[i + 1].dataset[option]) {
          /* If next item is alphabetically lower than current item,
          mark as a switch and break the loop: */
          shouldSwitch = true;
          break;
        }
      } else if (dir === 'asc') {
        if (item[i].dataset[option] < item[i + 1].dataset[option]) {
          /* If next item is alphabetically lower than current item,
          mark as a switch and break the loop: */
          shouldSwitch = true;
          break;
        }
      }
    }
    if (shouldSwitch) {
      /* If a switch has been marked, make the switch
      and mark the switch as done: */
      item[i].parentNode.insertBefore(item[i + 1], item[i]);
      switching = true;
    }
  }
};

export default sorter;
