// import '@babel/polyfill';
import '../../node_modules/regenerator-runtime/runtime.js';
import '../sass/stylesheets/main.scss';

import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import * as emCalc from './modules/emCalc';
import { handleImageUpload } from './modules/s3UploaderNew';
import { $, $$ } from './modules/bling';
import copyToken from './modules/copyToken';
import drawerLogic from './modules/drawer';
import formSubmit from './modules/formSubmit';
import inputEnabler from './modules/inputEnabler';
import { modal, modalLogic } from './modules/modal';
import sorter from './modules/sorter';
import { studentSearch, userSearch } from './modules/userSearch';
import felAdder from './modules/felAdder';
import { getPDF, savePDF } from './modules/pdfExport';

// Config Sentry
Sentry.init({
  dsn: 'https://5a884551d5664b84872c8767ba43bd66@sentry.io/1279173',
  environment: process.env.NODE_ENV,
});
// Init Log Rocket
if (process.env.NODE_ENV === 'production') {
  LogRocket.init('uerju4/mortraqr-c2sq7');
} else {
  LogRocket.init('uerju4/mortraqr-dev');
}
// attach user to loggin session
if ($('#data')) {
  const text = $('#data').innerText;
  const data = JSON.parse(text);

  const { email, id } = data;
  LogRocket.identify(id, {
    // name: 'James Morrison',
    email,
    // Add your own custom user variables here, ie:
    // subscriptionType: 'pro'
  });
  // capture the user for Sentry
  Sentry.configureScope(scope => {
    scope.setUser({ email });
  });
}
if ($('#photo')) {
  const photo = $('#photo');
  const form = photo.form;
  photo.onchange = async () => {
    await handleImageUpload(form);
  };
}

//- Polyfill for remove() for flashes
(function(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode !== null) this.parentNode.removeChild(this);
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

// check if multiple forms
if ($('form')) {
  const body = $('body');
  // console.log(body);
  body.addEventListener('submit', function(e) {
    formSubmit(e);
  });
}

window.morTraqrFun = {
  getPDF,
  savePDF,
  inputEnabler,
  copyToken,
  emCalc,
  felAdder,
  modal,
};
window.emCalc = emCalc.emCalc;
window.emCalcReset = emCalc.emCalcReset;
window.emCalcPrep = emCalc.emCalcPrep;

studentSearch($('.search'));
userSearch($('.userSearch'));
drawerLogic($('.button--accordion'));
modalLogic($('#modal'));
sorter($('#sort'));
