const inputEnabler = function inputEnabler(el) {
  let tag = document.getElementsByName(el);
  if (tag[0]) {
    tag = tag[0];
  }
  if (tag.disabled == true) {
    tag.disabled = false;
    if (tag.tagName == 'INPUT') {
      tag.select();
    }
  } else {
    tag.disabled = true;
  }
};

export default inputEnabler;
