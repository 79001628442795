const copyToken = function copyToken(el) {
  const copyText = el.childNodes[0];
  const range = document.createRange();
  range.selectNode(copyText);
  window.getSelection().addRange(range);

  try {
    // Now that we've selected the anchor text, execute the copy command
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copy email command was ' + msg);
    var tooltip = el.childNodes[2];
    tooltip.classList.toggle('visually-hidden');
    setTimeout(() => {
      tooltip.classList.toggle('visually-hidden');
    }, 2000);
  } catch (err) {
    console.log('Oops, unable to copy');
  }
  // Remove the selections - NOTE: Should use
  // removeRange(range) when it is supported
  window.getSelection().removeAllRanges();
};

export default copyToken;
