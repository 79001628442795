const felAdder = solutionNum => {
  // get total FELs
  const totalFEL = document.getElementsByName('emsol-' + solutionNum + '-fel')
    .length;
  const totalFELForArray = totalFEL - 1;
  if (totalFEL == 5) {
    alert('Sorry. Only a maximum of 5 FELs are currently supported');
    return;
  }
  const lastFEL = document.getElementsByName('emsol-' + solutionNum + '-fel')[
    totalFEL - 1
  ];
  // const lastFELParent = lastFEL.parentNode;
  // const lastLabelParent = lastFELParent.previousSibling.parentNode;
  // find the block where to put the new HTML
  // const solutionBlock = $('#emsol-' + solutionNum);

  // prepare the new HTML
  const prefix = 'emsol-' + solutionNum + '-fluid-' + totalFEL;
  const prefixForArray = `emsol-${solutionNum}-fluid-${totalFELForArray}`;

  // clone elements
  const inputDiv = lastFEL.parentNode.cloneNode(true);
  const labelDiv = lastFEL.parentNode.previousSibling.cloneNode(true);

  // adjust for values for label
  const nameLabel = labelDiv.querySelector(
    `[for="emsol-${solutionNum}-fluid-${totalFELForArray}"]`
  );
  // set text value
  nameLabel.textContent = 'Fluid Entry Line ' + (totalFEL + 1);

  // set for value
  nameLabel.setAttribute('for', prefix);

  // adjust values for inputs
  const nameInput = inputDiv.querySelector(`[name="emsol-${solutionNum}-fel"]`);
  const conInput = inputDiv.querySelector(`[name="emsol-${solutionNum}-con"]`);
  const volInput = inputDiv.querySelector(`[name="emsol-${solutionNum}-vol"]`);
  nameInput.id = prefix;
  conInput.id = prefix + '-con';
  volInput.id = prefix + '-vol';
  nameInput.value = '';
  conInput.value = '';
  volInput.value = '';
  // insert the new HTML
  // example: div.parentNode.insertBefore(newText, div.nextSibling);
  const lastFELContainer = lastFEL.parentNode;
  lastFELContainer.parentNode.insertBefore(
    inputDiv,
    lastFELContainer.nextSibling
  );
  lastFELContainer.parentNode.insertBefore(
    labelDiv,
    lastFELContainer.nextSibling
  );
  // increment the button
  event.target.dataset.solnum = solutionNum + 1;
};

export default felAdder;
