const formSubmit = function formSubmit(e) {
  const form = e.target;
  const button = form.querySelector('button[type="submit"]');
  if (!button) {
    return;
  }
  button.disabled = true;
  button.innerHTML = '<i class="fa fa-spinner fa-spin"></i> Please wait...';
};

export default formSubmit;
