const drawerLogic = function drawerLogic(drawer) {
  if (!drawer) {
    return;
  }
  let acc = document.getElementsByClassName('button--accordion');
  // console.log(acc);
  for (let i = 0; i < acc.length; i++) {
    acc[i].addEventListener('click', function() {
      /* Toggle between adding and removing the "active" class,
          to highlight the button that controls the panel */
      this.classList.toggle('active');

      /* Toggle between hiding and showing the active panel */
      var panel = this.nextElementSibling;
      panel.classList.toggle('open');
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 20 + 'px';
      }
    });
  }
};

export default drawerLogic;
